<table class="calendar-table">
  <tr class="calendar-row weekdays">
    <td *ngFor="let day of localeDays" class="calendar-cell weekday" role="heading" [attr.aria-label]="day.day">
      {{day.narrow}}
    </td>
  </tr>
  <tr class="calendar-row" *ngFor="let row of calendarViewModel.calendarView">
    <td *ngFor="let dayView of row" class="calendar-cell">
      <clr-day [clrDayView]="dayView"></clr-day>
    </td>
  </tr>
</table>
